import * as React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import { useStaticQuery, graphql } from "gatsby"

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();

  const meta_query = graphql`
  query MetaImgUrlQuery {
    markdownRemark {
      frontmatter {
        image {
          publicURL
        }
      }
    }
  }
  `
  const data = useStaticQuery(meta_query)

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />

        <link 
          rel="stylesheet" 
          href="https://use.typekit.net/mgh6vfj.css" 
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />

        <meta name="title" content="Maurice Mcleod: standing up for Camberwell & Peckham" />
        <meta name="description" content="I'm an anti-racism leader, Labour activist and councillor. I've been standing up for social justice my whole life. That’s what I’d do as your MP." />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://camberwellpeckham.uk" />
        <meta property="og:title" content="Maurice Mcleod: standing up for Camberwell & Peckham" />
        <meta property="og:description" content="I'm an anti-racism leader, Labour activist and councillor. I've been standing up for social justice my whole life. That’s what I’d do as your MP." />
        <meta property="og:image" content={data.markdownRemark.frontmatter.image.publicURL}/>

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://camberwellpeckham.uk" />
        <meta property="twitter:title" content="Maurice Mcleod: standing up for Camberwell & Peckham" />
        <meta property="twitter:description" content="I'm an anti-racism leader, Labour activist and councillor. I've been standing up for social justice my whole life. That’s what I’d do as your MP." />
        <meta property="twitter:image" content={data.markdownRemark.frontmatter.image.publicURL}/>
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
