import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";
import { Remarkable } from 'remarkable';
import ReactMarkdown from 'react-markdown'
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import SignupForm from "../components/SignupForm";
import ReactPlayer from "react-player"
import logo from "../img/logo.svg";

const md = new Remarkable();

const EndorsementQuotes = (quotes) => {
  return quotes.map((quote) => {
    return (
      <div className="columns">
        <div className="column">
          <GatsbyImage image={quote.image} />
        </div>
        <div className="column is-8">

          <h1 className="is-size-2 is-size-4-mobile">{quote.name}</h1>
          <h2 className="is-size-4">{quote.subtitle}</h2>
          <p className="is-size-5">{quote.quote}</p>
        </div>
      </div>
    )
  })
}

const EndorsementOrgs = (orgs) => {
  orgs = orgs.map((org) => {
    return <><h1>djdj</h1>{org.alt}</>
  })
}

const ImageSection = (content) => {

  const text_html = md.render(content.text)

  return <>
    {(content.image || content.caption) &&
      <div className="section">
        {content.image &&
          <div className="columns">
            <div className="column is-8 is-offset-4">
              <PreviewCompatibleImage imageInfo={content.image} alt={content.alt} />
            </div>
          </div>
        }
        {content.caption &&
          <div className="columns">
            <div className="column is-8 is-offset-4">
              <p>{content.caption}</p>
            </div>
          </div>
        }
      </div>
    }
    <div className="section">
      <div className="columns">
        <div className="column is-8 is-offset-4">
          {content.title && <h1 className="is-size-2 has-text-weight-bold is-size-4-mobile" >{content.title}</h1>}
        </div>
      </div>
      <div className="columns">
        <div className="column is-8 is-offset-4 is-size-5 column-flex" dangerouslySetInnerHTML={{ __html: text_html }}>
        </div>
      </div>
    </div>
  </>
}

const SectionHeader = (text) => {
  return <div className="section">
    <div className="columns">
      <div className="column is-12">
        <h1 className="is-size-1 standouthed is-size-3-mobile">{text}</h1>
      </div>
    </div>
  </div>
}

export const IndexPageTemplate = ({
  statement,
  standfirst,
  form,
  endorsements,
  life,
  work,
  goals,
}) => {

  const statement_html = md.render(statement)
  const standfirst_html = md.render(standfirst)

  return (
    <>
      <div>
        <section className="section videoSection" style={{ minHeight: '80vh' }}>
          <div className="is-hidden-tablet">
            <ReactPlayer
              height={"max-content"}
              width={"auto"}
              url="https://www.facebook.com/watch/?v=959469255027269"
              controls
              muted={true}
              playing={true}
              loop={true}
            />
          </div>
          <div className="container is-hidden-mobile">
            <div className="section">
              <div className="columns">
                <div className="column is-6 " style={{ display: 'flex', alignContent: 'flex-end', flexDirection: 'column', justifyContent: 'flex-end', alignContent: 'flex-end' }}>
                  <img src={logo} alt="Maurice Mcleod" style={{ maxHeight: "unset", width: "auto", paddingTop: '50vh' }} />
                </div>
                <div className="column is-6">
                  <ReactPlayer
                    height={"max-content"}
                    width={"auto"}
                    url="https://www.facebook.com/watch/?v=959469255027269"
                    controls
                    muted={true}
                    playing={true}
                    loop={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <section className="section section--gradient">
          <div className="container">
          <div className="section">
              <div className="columns">
                <div className="column-flex column is-size-5 is-8" >
                  <p><strong>Statement from Maurice Mcleod, 15th October 2022</strong></p>
                  <p>Last night, I was blocked by the Labour Party machine from standing to be the next Labour MP for Camberwell & Peckham.</p>
                  <p>I am deeply disappointed by this unfair decision taken behind closed doors, which denies local members the opportunity to vote for me. This comes despite huge support locally and despite receiving nominations from two trade unions, which according to Party rules should have guaranteed me a place on the longlist. The reasons given are as frivolous as once liking a tweet by Caroline Lucas. This was plainly a factional intervention.</p>
                  <p>I stood because the people of Camberwell and Peckham need a representative who understands their concerns and isn’t afraid to stand up for them. As a young man, I was stopped by the police over 100 times. I’ve attended too many funerals for those who’ve died due to street violence. I’ve lived in council housing since I was four. And I first became a Councillor in the wake of Grenfell, with my sister calling me every night, terrified that a block like hers would go up in flames.</p>
                  <p>I’ve dedicated my entire adult-life to fighting for black and working class people. As Political Editor of The Voice, Britain’s leading black newspaper, in the 1990s, I helped lead the fight for justice for Stephen Lawrence and reported on the McPherson report exposing institutional racism in the police. Recently, I was CEO of leading anti-racism charity Race on the Agenda, leading work on the impact of racist school exclusions and working with the TUC to highlight the pernicious effect of zero hour contracts on black and brown workers.</p>
                  <p>I am disappointed not just for myself, but for the people who volunteered their time to build an entire campaign from the ground up. But a larger feeling is sadness. I’m sad that Labour sees no place in it ranks for black and brown socialists like me who have fought relentlessly for racial justice, while claiming to take a strong stand on anti-racism.</p>
                  <p>Thank you so much to everyone who has supported me. Camberwell &; Peckham is a phenomenal place with an amazing, vibrant community and I take heart from the energy and potential of this campaign. There is still real support for socialist and anti-racist politics at the grassroots; this struggle, within and beyond the Labour Party, is too important to give up.</p>
                  <p>Between the candidates, the contest has been conducted respectfully and positively. I wish those who have been allowed to put themselves in front of the members all the very best.</p>
                  <p>Yours in solidarity,</p>
                  <p><strong>Maurice Mcleod</strong></p>
                </div>
              </div>
            </div>
            <hr/>
            <div className="section">
              <div className="columns">
                <div className="column-flex column is-size-5 is-8" dangerouslySetInnerHTML={{ __html: standfirst_html }}>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SignupForm form={form} name="signup" />
        <section className="section section--gradient">
          <div className="container">
            {SectionHeader("Why vote for Maurice?")}
            {ImageSection(life)}
            {SectionHeader("What I stand for")}
            {goals.subsections.map((section) => ImageSection(section))}
          </div>
        </section>
        <SignupForm form={form} name="signup" />
        <section className="section section--gradient">
          <div className="container">
            {SectionHeader("My experience")}
            {work.subsections.map((subsection) => ImageSection(subsection))}
          </div>
        </section>
        <SignupForm form={form} name="signup" />
      </div>
    </>
  );
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <>
      <Layout>
        <IndexPageTemplate
          heading={frontmatter.heading}
          subheading={frontmatter.subheading}
          mainpitch={frontmatter.mainpitch}
          description={frontmatter.description}
          intro={frontmatter.intro}
          standfirst={frontmatter.standfirst}
          form={frontmatter.form}
          endorsements={frontmatter.endorsements}
          life={frontmatter.life}
          work={frontmatter.work}
          goals={frontmatter.goals}

        />
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        statement: PropTypes.string,
        standfirst: PropTypes.string,
        form: PropTypes.object,
        endorsements: PropTypes.object,
        life: PropTypes.object,
        work: PropTypes.object,
        goals: PropTypes.object,
      })
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        statement
        standfirst
        form {
          title
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        endorsements {
          quotes {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            name
            subtitle
            quote
          }
          organisations {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            alt
          }
        }
        life {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          title
          alt
          caption
          text
        }
        work {
          subsections {
            title
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            alt
            caption
            text
          }
        }
        goals {
          subsections {
            title
            text
          }
        }
      }
    }
  }
`;
