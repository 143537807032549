import * as React from "react";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const Form = (form, name) => <form name={name} method="POST" data-netlify="true">


    <input type="hidden" name="form-name" value={name} />

    <p className="is-size-5">With your support, I can do this. Let me know I have your backing.</p>
    <br />
    <div className="field">
        <label className="label">First Name</label>
        <div className="control">
            <input class="input" type="text" name="first_name" required />
        </div>
    </div>

    <div className="field">
        <label className="label">Last Name</label>
        <div className="control">
            <input class="input" type="text" name="last_name" required />
        </div>
    </div>

    <div className="field">
        <label className="label">Email address</label>
        <div className="control">
            <input class="input" type="email" name="email" />
        </div>
    </div>

    <div className="field">
        <label className="label">Mobile number</label>
        <div className="control">
            <input class="input" type="text" name="mobile" />
        </div>
    </div>

    <div className="field">
        <label className="label">Street address</label>
        <div className="control">
            <input class="input" type="text" name="street_address" />
        </div>
    </div>

    <div className="field">
        <label className="label">Postcode</label>
        <div className="control">

            <input class="input" type="text" name="postcode" />
        </div>
    </div>

    <label className="label" style={{ display: 'flex', gap: "16px" }}>
        <input class="checkbox" type="checkbox" value="" name="agreed" />
        I live in Camberwell & Peckham
    </label>

    <label className="label" style={{ display: 'flex', gap: "16px" }}>
        <input class="checkbox" type="checkbox" value="" name="agreed" />
        I am a Labour Party member
    </label>

    <label className="label" style={{ display: 'flex', gap: "16px" }}>
        <input class="checkbox" type="checkbox" value="" name="agreed" />
        <span>
        I agree to be contacted by the Maurice Mcleod campaign according to the <a style={{color: "inherit", textDecorationLine: "underline"}} href="https://docs.google.com/document/d/1TFnWqyh_ETZ0WWDb9Eo9VA3H84CCnV_n3ntzeEpHflQ/edit?usp=sharing" rel="noopener noreferrer" target="_blank">privacy policy</a>
        </span>
    </label>
    <p>
        <button type="submit">Send</button>
    </p>
</form>

const SignupForm = ({ form, name }) => {
    console.log(form.image)
    return <>
        <section className="is-hidden-tablet">
            <PreviewCompatibleImage imageInfo={form.image}/>
            <h1 className="is-size-1 standouthed is-size-3-mobile">{form.title}</h1>
            <Form name={name} />
        </section>
        <section className="section mmform is-hidden-mobile">
            <div className="container ">
                <div className="section">
                    <div className="columns">
                        <div className="column is-6" style={{ display: 'flex', alignContent: 'flex-end', flexDirection: 'column', justifyContent: 'flex-end', alignContent: 'flex-end' }}>
                            <h1 className="is-size-1 standouthed is-size-3-mobile">{form.title}</h1>
                        </div>
                        <div className="column is-6">
                            <Form name={name} />
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </>
}

export default SignupForm;
